import "./App.css";
import logo from "./assets/logo.png";

function App() {
  return (
    <div className="container">
      <img src={logo} alt="Logo" className="logo" />
    </div>
  );
}

export default App;
